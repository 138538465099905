<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddAirDialog"
            >+ 新增设备</el-button
          >
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="airList" stripe border>
        <!-- 第一列id -->
        <el-table-column
          label="设备id"
          width="100"
          aligin="center"
          prop="deviceId"
        >
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column label="设备名称" aligin="center" prop="deviceName">
        </el-table-column>
        <el-table-column label="设备地址" aligin="center" prop="deviceAddr">
        </el-table-column>
        <el-table-column label="设备品牌" aligin="center" prop="brand">
        </el-table-column>
        <!-- 第三列备注 -->
        <el-table-column label="备注" aligin="center" prop="remark">
        </el-table-column>

        <!-- 第五列操作 -->
        <el-table-column label="操作" width="200" aligin="center">
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditQuestionDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeQuestionById(scope.row.deviceId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 新增对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQuestionDialogVisible"
      width="50%"
      @close="clearAddInfo"
    >
      <el-form
        ref="commonQuestionFormListRef"
        :rules="commonQuestionFormListRules"
        :model="airMonitorDeviceForm"
        label-width="80px"
      >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input
            v-model="airMonitorDeviceForm.deviceName"
            placeholder="请输入设备名称"
            clearable=""
          ></el-input>
        </el-form-item>
        <el-form-item label="设备地址" prop="deviceAddr">
          <el-input
            v-model="airMonitorDeviceForm.deviceAddr"
            placeholder="请输入设备地址"
            clearable=""
          ></el-input>
        </el-form-item>
        <el-form-item label="设备品牌" prop="brand">
          <el-input
            v-model="airMonitorDeviceForm.brand"
            placeholder="请输入设备品牌"
            clearable=""
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="airMonitorDeviceForm.remark"
            placeholder="请输入备注信息"
            clearable=""
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        pageNo: 1,
        limit: 15,
        groupId: '',
        marketId: ''
      },
      // 设备列表
      airList: [],
      // 总数
      total: 0,
      // 标题
      title: '',
      // 新增还是修改
      submitType: '',
      // 修改的id
      airId: '',
      id: [],
      // 对话框
      addQuestionDialogVisible: false,
      // 新增入参
      airMonitorDeviceForm: {
        brand: '', // 品牌
        deviceAddr: undefined, // 设备地址
        deviceName: '',
        groupId: undefined,
        marketId: undefined,
        remark: ''
      },
      // 校验
      commonQuestionFormListRules: {
        deviceName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        deviceAddr: [
          { required: true, message: '请输入设备地址', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 接收id参数
    this.queryInfo.groupId = this.$route.query.id
    this.airMonitorDeviceForm.groupId = this.$route.query.id

    // 请求该类型下的设备列表
    this.getAirList()
  },
  methods: {
    // 定义设备列表方法
    async getAirList() {
      // 给marketId赋值
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')

      const { data: res } = await this.$http.get('air/airMonitorDevice/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查询设备列表失败')
      }
      // 赋值
      this.airList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getAirList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getAirList()
    },
    // 点击新增按钮
    showAddAirDialog() {
      this.title = '新增设备'
      this.submitType = 'add'
      this.addQuestionDialogVisible = true
    },
    // 提交新增和修改请求
    async submit() {
      this.$refs.commonQuestionFormListRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          this.airMonitorDeviceForm.marketId = window.sessionStorage.getItem(
            'currentMarketId'
          )
          const { data: res } = await this.$http.post(
            'air/airMonitorDevice/',
            this.airMonitorDeviceForm
          )
          if (res.code !== 0) {
            return this.$message.error('添加设备失败')
          }
          this.$message.success('添加设备成功')
          this.getAirList()
          this.addQuestionDialogVisible = false
        } else {
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `air/airMonitorDevice/${this.airId}`,
            this.airMonitorDeviceForm
          )
          if (res.code !== 0) {
            return this.$message.error('修改设备失败')
          }
          this.$message.success('修改设备成功')
          this.getAirList()
          this.addQuestionDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo() {
      // 清空设备和办法，不能清除groupId和marketId
      this.airMonitorDeviceForm.brand = ''
      this.airMonitorDeviceForm.deviceAddr = ''
      this.airMonitorDeviceForm.deviceName = ''
      this.airMonitorDeviceForm.remark = ''
      this.getAirList()
    },
    // 编辑
    showEditQuestionDialog(row) {
      this.airMonitorDeviceForm = row
      this.addQuestionDialogVisible = true
      this.title = '修改设备'
      this.submitType = 'edit'
      // 赋值修改的id
      this.airId = row.deviceId
    },
    // 删除
    async removeQuestionById(deviceId) {
      this.id = [deviceId]
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该设备，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('air/airMonitorDevice/', {
        data: this.id
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('删除设备成功')
      this.getAirList()
    }
  }
}
</script>

<style lang="less" scoped></style>
